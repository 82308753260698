import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AuthContext } from 'shared/api';
import { clearProfile } from 'storage/profile';

export const useLogout = () => {
  const dispatch = useDispatch();
  const { setAuthToken } = useContext(AuthContext);

  return useCallback(() => {
    dispatch(clearProfile());
    setAuthToken(null);
    sessionStorage.removeItem('authToken');
  }, []);
};
