import React from 'react';

const TeamSettingsComponent = React.lazy(() => import('./TeamSettings.page'));

export const TeamSettings = () => {
  return (
    <React.Suspense>
      <TeamSettingsComponent />
    </React.Suspense>
  );
};
