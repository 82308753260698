import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRole } from 'storage/profile';
import { AppState } from 'storage/store';

import { useProfile } from './profile';

export const useRole = () => {
  const dispatch = useDispatch();
  const profile = useProfile();
  const role = useSelector((state: AppState) => state.profile.role);

  useEffect(() => {
    dispatch(setRole((profile.data?.organizations?.length || 0) > 1 ? 'superuser' : 'admin'));
  }, [dispatch, profile.data?.organizations]);

  return role;
};
