import React from 'react';

const DashboardComponent = React.lazy(() => import('./Dashboard.page'));

export const DashboardPage = () => {
  return (
    <React.Suspense>
      <DashboardComponent />
    </React.Suspense>
  );
};
