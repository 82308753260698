import { AddMember } from 'pages/AddMember';
import { DashboardPage } from 'pages/Dashboard';
import { LoginPage } from 'pages/login';
import { MainPage } from 'pages/Main';
import { MonitoringPage } from 'pages/Monitoring';
import { ResetPassword } from 'pages/ResetPassword';
import { SetPassword } from 'pages/SetPassword';
import { Settings } from 'pages/Settings';
import { TeamSettings } from 'pages/TeamSettings';
import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

const notFound = <Navigate to={'/dashboard'} />;

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={'/'}>
      <Route Component={PublicRoute}>
        <Route path="login" Component={LoginPage} />
        <Route path="reset-password" Component={ResetPassword} />
        <Route path="set-password" Component={SetPassword} />
      </Route>

      <Route Component={PrivateRoute}>
        <Route path={'dashboard'} Component={DashboardPage} />
        <Route path={'clients'} Component={MainPage} />
        <Route path={'monitoring'} Component={MonitoringPage} />
        <Route path={'settings'} Component={Settings} />
        <Route path={'team-settings'} Component={TeamSettings} />
        <Route path={'add-member'} Component={AddMember}></Route>
        <Route path={'*'} element={notFound} />
        <Route index element={notFound} />
      </Route>
    </Route>,
  ),
);
