import React from 'react';

const AddMemberComponent = React.lazy(() => import('./AddMember.page'));

export const AddMember = () => {
  return (
    <React.Suspense>
      <AddMemberComponent />
    </React.Suspense>
  );
};
