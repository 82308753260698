import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Organization = {
  id: string;
  name: string;
};

type Role = 'superuser' | 'admin' | 'user';

type ProfileStorage = {
  organization?: Organization;
  role?: Role;
};

const initialState: ProfileStorage = {};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setOrganization: (state, { payload }: PayloadAction<Organization>) => {
      state.organization = payload;
    },
    setRole: (state, { payload }: PayloadAction<Role>) => {
      state.role = payload;
    },
    clearProfile: (state) => {
      state.organization = undefined;
      state.role = undefined;
    },
  },
});

export const { setOrganization, setRole, clearProfile } = profileSlice.actions;
