import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { AppState } from '../../storage/store';

const Monitoring = React.lazy(() => import('./Monitoring.page'));

export const MonitoringPage = () => {
  const role = useSelector((state: AppState) => state.profile.role);

  return useMemo(() => {
    if (role == 'superuser') {
      return (
        <React.Suspense>
          <Monitoring />
        </React.Suspense>
      );
    }

    return <Navigate to={'/dashboard'} />;
  }, [role]);
};
